import { useEffect, useState } from 'react'

import { Form, Input, Button, Table, Image, Row } from 'antd'
//import 'antd/dist/antd.css'

import axios from 'axios'


function Checkin() {
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [checkinRecords, setCheckinRecords] = useState([])
    const [captchaSrc, setCaptchaSrc] = useState("")
    const [errMsg, setErrMsg] = useState("")

    const columns = [
        {
            "title": "Date",
            "dataIndex": "punchDate",
        },
        {
            "title": "Time",
            "dataIndex": "punchTime",
        }
    ]

    function arrayBufferToBase64(bytes) {
        const str = String.fromCharCode(...new Uint8Array(bytes));
        return window.btoa(str);
    }

    const refreshCaptcha = () => {
        axios.get('captcha', {
            responseType: 'arraybuffer',
        }).then(res => {
            const imgBase64 = arrayBufferToBase64(res.data);
            const imgSrc = `data:image/gif;base64,${imgBase64}`;
            setCaptchaSrc(imgSrc);
        })
    }

    const onFinish = (values) => {
        setLoading(true)
        setDisabled(true)
        setErrMsg("")
        let data = {
            username: values.username.trim(),
            password: values.password.trim(),
            captcha: values.captcha.trim(),
        }
        axios.post('checkin', data)
        .then(res => {
            setCheckinRecords(res.data)
        })
        .catch(err => {
            console.log(err)
            setErrMsg(JSON.stringify(err.response.data) || err.response.data.toString())
        })
        .finally(() => {
            setLoading(false)
            setDisabled(false)
            refreshCaptcha();
        })
    }

    useEffect(()=> {
        refreshCaptcha();
    }, []);

    return (
        <div>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Employee Account',
                        }
                    ]}
                >
                    <Input placeholder="Employee Account" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input password!',
                        }
                    ]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Row>
                <Form.Item
                    name="captcha"
                    rules={[
                        {
                            required: true,
                            message: 'Please input captcha!',
                        }
                    ]}
                >
                    <Input placeholder="Captcha" />

                </Form.Item>
                <Image
                    src={captchaSrc}
                />
                </Row>
                <Form.Item>
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={disabled}
                    >
                        Checkin
                    </Button>
                </Form.Item>
            </Form>

            <Table
                pagination={false}
                dataSource={checkinRecords}
                columns={columns}
            />

            <Input.TextArea
                value={errMsg}
                hidden={errMsg === ""}
                autoSize
            />

        </div>
    )
}

export default Checkin